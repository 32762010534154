import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

import "./global/src/bugsnag.js"
import "./global/src/localtime.js"
import "./global/src/tippy.js"
import "./global/src/modal.js"
import "./global/src/stream_actions.js"
import "./application/src/jquery.js"
import "./application/src/chartkick.js"
import "./application/src/sidebar.js"
import "./application/src/datatables.js"

import "./global/listeners"
import "./application/listeners"

import "./global/controllers"
import "./auth/controllers"
import "./application/controllers"

import "./application/channels"

Rails.start();
