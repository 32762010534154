import { Controller } from "@hotwired/stimulus";


function errorMessageForValidityState(validityState, fieldName) {
  if (validityState.valueMissing) {
    return `${fieldName} cannot be blank`
  } else if (validityState.rangeUnderflow) {
    return `${fieldName} is too low`
  } else if (validityState.rangeOverflow) {
    return `${fieldName} is too high`
  } else if (validityState.stepMismatch){
    return `${fieldName} can only be a whole number`
  } else {
    return `${fieldName} is invalid.`
  }
}

export default class extends Controller {
  static targets = [
    "current",
    "formContainer",
    "submitButton",
    "form",
    "modalContent",
    "validatable",
    "fieldset"
  ];

  connect() {
    document.addEventListener("options-editor-confirm", (e) => {
      this.formTarget.requestSubmit();
    }, false)
    document.addEventListener("options-editor-cancel", (e) => {
      this.cancel(e);
    }, false)
  }

  edit(e) {
    e.preventDefault();
    fetch(e.currentTarget.href)
      .then(response => response.text())
      .then(text => {
        Turbo.renderStreamMessage(text)
        this.currentTarget.style.display = "none";
        this.formContainerTarget.style.display = "block";
      })
  }

  formTargetConnected() {
    const event = new Event('app.tippy.refresh')
    document.dispatchEvent(event);
  }

  cancel(e) {
    e.preventDefault();
    this.submitButtonTarget.disabled = false;
    this.formTarget.reset();
    this.currentTarget.style.display = "block";
    this.formContainerTarget.style.display = "none";
  }

  clearErrors() {
    this.validatableTargets.forEach((input) => {
      let span = input.querySelector("span.error");
      input.classList.remove("field_with_errors");
      span?.remove();
    })
  }

  validateThenSubmit(e) {
    e.preventDefault();
    this.clearErrors();
    this.submitButtonTarget.disabled = true;

    let formValid = true;

    this.formTarget.noValidate = false;
    this.validatableTargets.forEach((input) => {
      if ( !input.checkValidity() ) {
        formValid = false
        let div   = input.closest("div.input"),
          span  = document.createElement("span");

        span.classList.add("error")
        span.innerHTML = errorMessageForValidityState(input.validity, div.dataset.name);
        div.classList.add("field_with_errors")
        div.appendChild(span);
      }
    });
    this.fieldsetTargets.forEach((fieldset) => {
      let inputs = Array.from(fieldset.querySelectorAll('input')); // JS is silly
      let invalid = inputs.filter((e) => e.value.length == 0).length > 0;
      if(invalid) {
        formValid = false;
        fieldset.classList.add("invalid");
      }
    })
    this.formTarget.noValidate = true;
    if(formValid && this.hasModalContentTarget) {
      let modal = kModal.init({
        content: this.modalContentTarget.innerHTML,
        launchingUrl: "",
        ujs: true,
        namespace: "options-confirmation-dialogue",
        appendTo: document.body,
      });
    } else if (formValid) {
      this.formTarget.requestSubmit()
    } else {
      this.submitButtonTarget.disabled = false;
    }
  }

  updateDTandHideModal(e) {
    try {
      kModal.hideAll();
    } catch (e) {
      // the modal isn't always visible, if it isn't, don't try to hide it. This
      // keeps us from ending up with console errors
    }
    let tableEl = $('table[id^=DataTables_Table]')
    if (tableEl.length > 0) {
      tableEl.first().DataTable().draw(false)
    }
  }
}
